import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="row privacy-policy" style={{ paddingTop: 80 }}>
        <h1>Policies, Terms &amp; Conditions</h1>
        <h2>The Type of Information Our Website Collects About You</h2>
        <p>
          We collect information from you when you register on our website,
          Subscribe to our newsletter. When registering on our website, you may
          be asked to enter your name, e-mail address, phone number.
        </p>
        <h2>How Coffee &amp; Motivation Uses Information About You</h2>
        <p>
          We use information about you to provide you with the very best online
          shopping experience. We do not sell your personal information to third
          parties for any reason. We use the information we collect from you in
          several ways:
          <ul>
            <li>
              To personalize your experience. Your information helps us to
              better respond to your individual needs.
            </li>
            <li>
              To improve our website. We’re continually improving our website
              based on the information and feedback we receive from you.
            </li>
            <li>
              To improve customer service. Your information helps us to more
              effectively respond to your customer service requests and support
              needs.
            </li>
            <li>
              To process transactions. Your information, whether public or
              private, will not be sold, exchanged, transferred or given to any
              other company for any reason whatsoever without your consent,
              other than for the express purpose of delivering the purchased
              product or service requested.
            </li>
            <li>
              To administer a contest, promotion, survey or other site feature.
            </li>
            <li>
              To send periodic emails. The email address you provide for order
              processing may be used to send you information and updates
              pertaining to your order or to occasionally send you company news,
              updates, related product or service information, etc.
            </li>
          </ul>
        </p>
        <h2 id="gud">Google User Data</h2>
        <p>
          The Website may use third party services to access your Google user
          data, only upon your verification and consent to do so. You will be
          notified prior to accessing your Google user data, and must authorize
          your account credentials before you are capable of accessing your
          Google user data. The Google user data you use will not be stored or
          shared by us. The Google user data accessed by the Website is limited
          to user contacts, and are used solely to allow you to email those
          contacts directly from the Website.
        </p>
        <h2>Opt-Out</h2>
        <p>
          You have options regarding the collection, use and sharing of your
          Personal Information under certain circumstances. When you receive
          promotional communications from us, you may specify a preference to
          stop receiving further promotional communications from us and you will
          have the chance to “opt-out” by following the unsubscribe instructions
          indicated in the communication you receive. You may also contact us
          via email at share@coffeeandmotivation.com to opt-out of receiving any
          promotional communications. (Please include “Opt-Out” in the subject
          line.) Despite your selected preferences, we may send you
          non-marketing communications regarding the services we provide to you,
          such as notices about administrative updates, transaction reports,
          notices of new Website releases, and notices regarding changes to the
          Website, updates to our Privacy Policy, Terms of Use, or other
          policies.
        </p>
        <h2>Unsubscribe from Our Newsletter</h2>
        <p>
          Should you no longer wish to receive our any of our Newsletters to
          your inbox, you may click on the unsubscribe link located at the
          bottom of each message received and we will immediately remove you
          from our distribution list.
        </p>
        <h2>Cookies and Similar Technologies</h2>
        <p>
          We may use pixel tags, cookies, and/or other similar technologies to
          collect visitor information. Cookies, for example, are alphanumeric
          identifiers that we transfer to your computer&apos;s hard drive
          through your web browser. They make it possible for us to save the
          items in your shopping cart until you are ready to check out,
          recognize your browser when you visit, recognize if you are a current
          subscriber, provide you with information about products that interest
          you, or analyze how our Website is used. By doing this, we can
          customize your return visits and save you time during checkout. It is
          possible to deactivate cookies from being used in your browser by
          turning the feature off, but in order to enjoy the benefits of being a
          registered user, your browser must be set to accept cookies. We do not
          use cookies to conducted online targeted advertising. Most web
          browsers automatically accept cookies, but if you prefer, you can edit
          your browser options to block them in the future. The Help portion of
          the toolbar on most browsers will tell you how to prevent your
          computer from accepting new cookies, how to have the browser notify
          you when you receive a new cookie, or how to disable cookies
          altogether. Visitors to our Website who disable cookies will be able
          to browse most areas of the Website, but some features may not
          function. You can find out more information about how to change your
          browser cookie settings at www.allaboutcookies.org
        </p>
        <h2>How Do We Use Personal Information?</h2>
        <p>
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to: - Communicate with you; - Screen our orders for
          potential risk or fraud; and - When in line with the preferences you
          have shared with us, provide you with information or advertising
          relating to our products or services. We use the Device Information
          that we collect to help us screen for potential risk and fraud (in
          particular, your IP address), and more generally to improve and
          optimize our Site (for example, by generating analytics about how our
          customers browse and interact with the Site, and to assess the success
          of our marketing and advertising campaigns).
        </p>
        <h2>Sharing Your Personal Information</h2>
        <p>
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. For example, we use
          Shopify to power our online store--you can read more about how Shopify
          uses your Personal Information here:
          https://www.shopify.com/legal/privacy. We also use Google Analytics to
          help us understand how our customers use the Site -- you can read more
          about how Google uses your Personal Information here:
          https://www.google.com/intl/en/policies/privacy/. You can also opt-out
          of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights. BEHAVIOURAL ADVERTISING As described
          above, we use your Personal Information to provide you with targeted
          advertisements or marketing communications we believe may be of
          interest to you. For more information about how targeted advertising
          works, you can visit the Network Advertising Initiative’s (“NAI”)
          educational page at
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          You can opt out of targeted advertising by using the links below: -
          Facebook: https://www.facebook.com/settings/?tab=ads - Google:
          https://www.google.com/settings/ads/anonymous - Bing:
          https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          Additionally, you can opt out of some of these services by visiting
          the Digital Advertising Alliance’s opt-out portal at:
          http://optout.aboutads.info/. DO NOT TRACK Please note that we do not
          alter our Site’s data collection and use practices when we see a Do
          Not Track signal from your browser. YOUR RIGHTS If you are a European
          resident, you have the right to access personal information we hold
          about you and to ask that your personal information be corrected,
          updated, or deleted. If you would like to exercise this right, please
          contact us through the contact information below. Additionally, if you
          are a European resident we note that we are processing your
          information in order to fulfill contracts we might have with you (for
          example if you make an order through the Site), or otherwise to pursue
          our legitimate business interests listed above. Additionally, please
          note that your information will be transferred outside of Europe,
          including to Canada and the United States.
        </p>
        <h2>Data Retention</h2>
        <p>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <h2>Changes</h2>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <h2>Minors</h2>
        <p>The Site is not intended for individuals under the age of 18.</p>
        <h2>Contact Us</h2>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e‑mail at info@coffeeandmotivation.com or by mail using the details
          provided below:
          <br />
          Coffee &amp; Motivation Company
          <br />
          [Re: Privacy Compliance Officer]
          <br />
          20533 Biscayne Blvd Aventura Florida US 33180
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
