import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.scss';

const Footer = () => {

  const d = new Date();
  const n = d.getFullYear();

  return (
    <footer style={{ backgroundColor: 'black' }}>
      <div className="row">
        <Link className="link" to="/policies-terms-and-conditions">
          Privacy Policies
        </Link>
        <span style={{ color: '#545454', margin: '0 10px' }}>|</span>
        {''}
        <Link className="link" to="/policies-terms-and-conditions">
          Terms &amp; Conditions
        </Link>
        <span style={{ color: '#545454', margin: '0 10px' }}>|</span>
        {''}
        <span>&copy; {n} Coffee and Motivation Co. All Rights Reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
