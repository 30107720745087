import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import WelcomePage from '../pages/WelcomePage';
import AsyncComponent from '../utils/AsyncComponent';

import '../styles/App.scss';
import PrivacyPolicy from '../pages/PrivacyPolicy';

const AsyncReferAFriendPage = AsyncComponent(() =>
  import('../pages/ReferAFriendPage')
);

const App = () => (
  <Router>
    <>
      <div>
        <Route exact path="/" component={WelcomePage} />
        <Route path="/refer-a-friend" component={AsyncReferAFriendPage} />
        <Route path="/policies-terms-and-conditions">
          <PrivacyPolicy />
        </Route>
      </div>
    </>
  </Router>
);

export default App;
