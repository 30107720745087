import React from 'react';
import loader from '../images/loader.svg';
import '../styles/Loader.scss';

const Loader = () => {
  return (
    <div className="loader">
      <img alt="loader" src={loader} />
    </div>
  );
};

export default Loader;
