import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dots from './Dots';
import stars from '../images/stars.svg';

const Slider = props => {
  const {
    data,
    width,
    fullWidth,
    navigation,
    navPosition,
    thumbSize
  } = props;
  const [slide, setSlide] = useState(0);
  const [pause, setPause] = useState(false);
  const containerWidth = width + thumbSize + 10; // slide width + thumbnail width + padding

  const s = {
    containerNavLeft: {
      width: containerWidth,
      alignItems: 'flex-start'
    },
    fullWidth: {
      width: '100%'
    },
    navPositionLeft: {
      flexDirection: 'row-reverse'
    },
    transitionGroup: {
      display: 'block'
    },
    navigation: {
      background: 'none',
      border: 'none',
      position: 'absolute',
      top: '50%',
      left: {
        transform: 'rotate(0.5turn)',
        left: 0
      },
      right: {
        right: 0
      }
    }
  };

  function handleNext() {
    if (slide >= data.length - 1) {
      setSlide(0);
    } else {
      setSlide(slide + 1);
    }
  }

  function handlePause() {
    if (pause === false) {
      setPause(true);
    } else {
      setPause(false);
    }
  }

  function handleSelectSlide(i) {
    setSlide(i);
  }

  useEffect(() => {
    if (props.autoplay === true && pause === false) {
      const timer = setInterval(() => {
        handleNext();
      }, props.delay);
      return () => clearInterval(timer);
    }
  });

  function layout() {
    let result = s.container;
    if (fullWidth) {
      result = { ...s.container, ...s.fullWidth };
    }

    if (navPosition === 'left') {
      result = { ...s.containerNavLeft, ...s.navPositionLeft };
    }
    return result;
  }

  return (
    <div
      className="uber-slider"
      onMouseEnter={handlePause}
      onMouseLeave={handlePause}
      style={layout()}
    >
      <div className="testimonial" key={data[slide].key}>
        <img src={stars} alt="star rating" style={{ paddingBottom: 20 }} />
        <p>{data[slide].content}</p>
        <span>{data[slide].name}</span>
      </div>
      {navigation === 'dots' && (
        <Dots
          count={data}
          index={slide}
          handleSelectSlide={handleSelectSlide}
        />
      )}
    </div>
  );
};

Slider.propTypes = {
  width: PropTypes.number,
  data: PropTypes.array,
  autoplay: PropTypes.bool,
  delay: PropTypes.number,
  fullWidth: PropTypes.bool,
  navigation: PropTypes.string,
  navPosition: PropTypes.string,
  thumbSize: PropTypes.number
};

Slider.defaultProps = {
  width: 600,
  autoplay: false,
  delay: 7000,
  fullWidth: false,
  navigation: '',
  navPosition: 'bottom',
  thumbSize: 75
};

export default Slider
