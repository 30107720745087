import React from 'react';
import PropTypes from 'prop-types';

const style = {
  dot: {
    backgroundColor: 'rgba(255,255,255,.5)',
    display: 'inline-block',
    height: 10,
    margin: 5,
    padding: 0,
    width: 10,
    borderRadius: '50%',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  active: {
    backgroundColor: 'rgba(255,255,255,1)'
  }
};

function Dots(props) {
  const { count, index } = props;
  return (
    <div>
      {count.map((dot, i) => {
        if (index === i) {
          return (
            <button
              onClick={() => props.handleSelectSlide(i)}
              style={{ ...style.dot, ...style.active }}
              key={count}
              type="button"
              label="button"
            />
          );
        }
        return (
          <button
            onClick={() => props.handleSelectSlide(i)}
            style={style.dot}
            key={dot.key}
            type="button"
            label="dot"
          />
        );
      })}
    </div>
  );
}

Dots.propTypes = {
  index: PropTypes.number.isRequired,
  count: PropTypes.array.isRequired,
  handleSelectSlide: PropTypes.func.isRequired
};

Dots.defaultProps = {};

export default Dots;
