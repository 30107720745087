import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../styles/Header.scss';
import logo from '../images/logo.png'

const Header = ({ bgcolor, authenticated, email, handleLogout }) => {
  return (
    <header className="header" style={{ backgroundColor: bgcolor }}>
      <div className="inner">
        <Link to="/">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <span
          className="menu"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {authenticated && (
            <>
              <a
                style={{ fontWeight: 'bold' }}
                href="https://www.coffeeandmotivation.com/?utm_source=sharepage&utm_medium=shopnow"
              >
                SHOP NOW
              </a>
              <span style={{ color: '#e4e4e4', margin: '0 10px' }}>|</span>{' '}
              <a
                style={{ fontWeight: 'bold' }}
                href="https://www.coffeeandmotivation.com/pages/about-us"
              >
                ABOUT
              </a>
              <span style={{ color: '#e4e4e4', margin: '0 10px' }}>|</span>{' '}
              <button
                className="link"
                data-cy="logout"
                onClick={() => handleLogout()}
                style={{ fontWeight: 'bold' }}
                type="button"
                title={`logged in as: ${email}`}
              >
                LOGOUT
              </button>
            </>
          )}
        </span>
      </div>
    </header>
  );
};

Header.propTypes = {
  bgcolor: PropTypes.string,
  handleLogout: PropTypes.func,
  email: PropTypes.string,
  authenticated: PropTypes.bool
};

Header.defaultProps = {
  bgcolor: '#fff',
  handleLogout: () => {},
  email: '',
  authenticated: false
};

export default Header;
