import React from 'react'
import PropTypes from 'prop-types'

const Feature = ({icon, title, content }) => {
  return (
    <div className="feature">
      <img src={icon} alt="icon" />
      <div style={{ paddingLeft: 20 }}>
        <h3>{title}</h3>
        <p>
          {content}
        </p>
        <a href="#top">Sign Up Now</a>
      </div>
    </div>
  );
}

Feature.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default Feature
