import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Loader from '../components/Loader';
import Feature from '../components/Feature';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import { getCookie, setCookie, getUrlVars } from '../utils';
import { config } from '../config';
import 'normalize.css';
import '../styles/WelcomePage.scss';
import pig from '../images/pig.svg';
import trophy from '../images/trophy.svg';
import newsletter from '../images/newsletter.svg';
import gift from '../images/gift.svg';

const testimonials = [
  {
    key: 0,
    content: 'I needed this today more than you will ever know',
    name: '- Justin'
  },
  {
    key: 1,
    content: 'I always love getting these emails, but this one couldn\'t have come at a better time! Thank you!! I always look forward to seeing these pop-up in my inbox.',
    name: '- Kristi'
  },
  {
    key: 2,
    content:
      'Not sure if you are getting this but thank you for these emails. Being a business owner during these times is very challenging',
    name: '- Rebecca'
  }
];
class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      referrerId: '',
      redirect: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // get referrer id from url
    if (getCookie('advocate_id')) {
      this.setState({
        referrerId: getCookie('advocate_id'),
        redirect: true
      });
    }

    if (getUrlVars().ref !== undefined) {
      this.setState({
        referrerId: getUrlVars().ref
      });
    } else {
      this.setState({
        referrerId: ''
      });
    }
    window.fbq('track', 'ViewContent');
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { value, referrerId } = this.state;

    this.setState({
      loading: true,
    });

    fetch(
      `${config.apiUrl}signup?value=${value.toLowerCase()}&referrerId=${referrerId}`,
      {
        method: 'GET'
      }
    )
      .then(response =>
        response.json().then(data => ({ status: response.status, body: data }))
      )
      .then(obj => {
        switch (obj.status) {
          case 201:
            setCookie('advocate_id', obj.body.advocateId, 365);
            if (window.gtag) {
              window.gtag('event', 'new_sign_up', {
                event_label: 'New Sign Up',
                event_category: 'landing_page'
              });

              if (referrerId.length > 0) {
                window.gtag('event', 'referral_sign_up', {
                  event_label: 'Referral Sign Up',
                  event_category: 'landing_page'
                });
              }
            }
            window.fbq('track', 'Lead');
            this.setState({
              redirect: true
            });
            break;
          case 200:
            setCookie('advocate_id', obj.body.advocateId, 365);
            if (window.gtag) {
              window.gtag('event', 'sign_in', {
                'event_label': 'Sign In',
                'event_category': 'landing_page'
              });
            }
            this.setState({
              redirect: true
            });
            break;
          default:
        }
      });
  }

  render() {
    const {
      loading,
      value,
      redirect,
    } = this.state;

    return !loading ? (
      <>
        <Header />
        <div className="welcome-page" id="top">
          <div className="hero">
            <div className="row">
              <div className="left">
                <h2 style={{ paddingBottom: 25 }}>
                  Sign Up For Our Free Motivational Newsletter
                </h2>
                <p>
                  Sign up for our <strong>FREE MOTIVATIONAL NEWSLETTER.</strong>{' '}
                  Our emails are never long or make you feel like someone is
                  preaching to you. Just two friends hanging out helping each
                  other grow. So what are you waiting for? sign up now to get
                  your morning motivation.
                </p>
                <form onSubmit={this.handleSubmit} className="input-group">
                  <input
                    type="email"
                    required
                    value={value.toLowerCase()}
                    onChange={this.handleChange}
                    placeholder="enter your best email"
                  />
                  <button type="submit">
                    TRY IT{' '}
                    <span role="img" aria-label="thumbs_up">
                      👍
                    </span>
                  </button>
                </form>
                <p style={{ paddingTop: 25 }}>
                  <strong>Already Signed up?</strong>
                  <br />
                  No worries, enter your email to check your points &amp;
                  prizes.
                </p>
              </div>
              <div className="right">
                <img
                  className="phone"
                  alt="iPhone Preview"
                  src="/images/phone-preview.png"
                />
              </div>
            </div>
          </div>

          <div className="features row">
            <Feature
              icon={newsletter}
              title="Join 100K Go Getters"
              content="Trusted by Go Getters just like you. We often share our fav quotes, what books we are reading or words of encouragement to help you through a rough day, tips and tricks to stay motivated to achieve your goals."
            />
            <Feature
              icon={gift}
              title="Win Free Prizes"
              content="You can win FREE PRIZES by sharing our motivational newsletter with your friends, family or co-workers. It only takes 5 minutes to get started."
            />
            <Feature
              icon={pig}
              title="Free Forever"
              content="When you sign up today you will never ever ever have to pay for access to the Coffee &amp; Motivation Newsletter. "
            />
            <Feature
              icon={trophy}
              title="Stay Motivated"
              content="Stay motivated to achieve your goals! Our emails are never long or make you feel like someone is preaching to you. Just two friends hanging out helping each other grow. So what are you waiting for? sign up now to get your morning motivation."
            />
          </div>

          <div className="footer">
            <div className="row">
              <h2>#coffeeandmotivation</h2>
              <div className="testimonials">
                <Slider
                  data={testimonials}
                  autoplay
                  fullWidth
                  navigation="dots"
                  delay={7000}
                />
              </div>
              <a href="#top">Sign Up Today</a>
            </div>
          </div>
        </div>
        <Footer />
        {redirect && <Redirect to="/refer-a-friend" />}
      </>
    ) : (
      <>
        <Loader />
        {redirect && <Redirect to="/refer-a-friend" />}
      </>
    );
  }
}

export default WelcomePage;
